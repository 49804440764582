/* Container for the entire formula details component */
.formula-details-container {
    margin-top: 0; /* Aligns with the perfume cards */
    padding: 30px;
    background-color: #f9fafb;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    width: 400px; /* Adjust width to match the formula details on the side */
}

/* Perfume information */
.perfume-info {
    margin-bottom: 20px;
}

.perfume-info p {
    font-size: 1.1rem;
    color: #34495e;
}

/* Ingredient list styling */
.ingredient-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Individual ingredient card */
.ingredient-card {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s ease;
}

.ingredient-card:hover {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
}

/* Ingredient name */
.ingredient-card h3 {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: #2c3e50;
}

/* Compliance history section */
.compliance-history h4 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 8px;
    color: #2c3e50;
}

.compliance-history ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 1rem;
    color: #34495e;
}

/* Percentage bar container */
.percentage-bar-container {
    margin-top: 10px;
    margin-bottom: 20px;
}

.percentage-bar {
    width: 100%;
    background-color: #ecf0f1;
    border-radius: 10px;
    height: 10px;
}

.fill-bar {
    background-color: #3498db;
    height: 100%;
    border-radius: 10px;
}

/* Supplier and origin details */
.ingredient-card p {
    font-size: 1rem;
    color: #34495e;
}

/* Compliance badges container */
.ingredient-compliance-badges {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

/* Generic badge styling */
.badge {
    font-size: 0.85rem;
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 12px;
    color: white;
}

/* Badge Colors */
.badge-ifra { background-color: #3498db; }
.badge-msds { background-color: #2ecc71; }
.badge-sustainable { background-color: #27ae60; }
.badge-vegan { background-color: #f39c12; }
.badge-eco { background-color: #8e44ad; }
.badge-reach { background-color: #34495e; }

/* Alternative ingredients section */
.alternative-ingredients h4 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 8px;
    color: #2c3e50;
}

.alternative-ingredients ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 1rem;
    color: #34495e;
}

/* Security note */
.security-note {
    font-size: 0.9rem;
    color: #e74c3c;
    margin-top: 15px;
}
