.sidebar {
    width: 250px;
    background-color: #2c3e50;
    color: #ffffff;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .logo {
    margin-bottom: 40px;
    text-align: center;
  }
  
  .logo h1 {
    font-size: 1.8rem;
    font-weight: 900;
    color: #ecf0f1;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    background: linear-gradient(90deg, #3498db, #9b59b6); /* Gradient for modern look */
    -webkit-background-clip: text;
    color: transparent; /* To allow gradient text */
    font-family: "Montserrat", sans-serif;
  }
  
  .sidebar ul {
    flex-grow: 1;
    font-family: "Montserrat", sans-serif;
  }
  
  .sidebar ul li {
    margin-bottom: 20px;
  }
  
  .sidebar ul li a {
    color: #ecf0f1;
    font-size: 1rem;
    font-weight: 500;
    padding: 10px 15px;
    border-radius: 8px;
    display: block;
    transition: background-color 0.3s ease-in-out;
  }
  
  .sidebar ul li a:hover,
  .sidebar ul li a.active {
    background-color: #34495e;
    color: #ffffff;
  }
  
  .sidebar ul li a:focus {
    background-color: #3498db;
  }
  
  .popular-sections h3 {
    color: #95a5a6;
    font-size: 1rem;
    margin-top: 40px;
    letter-spacing: 0.5px;
  }
  
  .popular-sections ul li a {
    color: #bdc3c7;
    font-size: 0.9rem;
    font-weight: 400;
  }
  