/* Modal Background Overlay */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  /* Floating Card */
  .modal-content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    max-width: 90%; /* Responsive for smaller screens */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease-out; /* Optional: smooth fade-in */
  }
  
  .modal-content h2 {
    margin-top: 0;
    font-size: 1.5rem;
    color: #34495e;
  }
  
  .modal-content form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .modal-content label {
    font-size: 1rem;
    color: #34495e;
  }
  
  .modal-content input[type="text"],
  .modal-content input[type="date"] {
    padding: 8px;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
  }
  
  .modal-content button {
    background-color: #3498db;
    color: #ffffff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .modal-content button[type="button"] {
    background-color: #e74c3c; /* Different color for Cancel button */
  }
  
  .modal-content button:hover {
    background-color: #2980b9;
  }
  
  .modal-content button[type="button"]:hover {
    background-color: #c0392b; /* Darker Cancel button on hover */
  }
  
  /* Optional Fade-In Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  