.compliance-automation {
    padding: 20px;
  }
  
  .compliance-automation ul {
    list-style: none;
    padding-left: 0;
  }
  
  .compliance-automation ul li {
    background-color: #ffffff;
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .compliance-automation ul li:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .compliance-automation ul li h3 {
    margin-bottom: 10px;
    font-size: 1.25rem;
    color: #2c3e50;
  }
  
  .compliance-automation ul li p {
    color: #7f8c8d;
    font-size: 0.95rem;
  }
  