* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Inter', sans-serif;
    background-color: #f5f7fa;
    color: #333;
    line-height: 1.6;
  }
  
  h1, h2, h3 {
    margin-bottom: 20px;
    color: #2c3e50;
    font-weight: 600;
  }
  
  h3 {
    font-size: 1.25rem;
  }
  
  a {
    text-decoration: none;
    color: #3498db;
    transition: color 0.2s ease-in-out;
  }
  
  a:hover {
    color: #2980b9;
  }
  
  ul {
    list-style-type: none;
  }
  
  .app-container {
    display: flex;
    height: 100vh;
  }
  
  .main-content {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
  }
  
  .card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.05);
    padding: 20px;
    margin-bottom: 30px;
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .card:hover {
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1);
  }
  
  button {
    background-color: #3498db;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  button:hover {
    background-color: #2980b9;
  }
  
  