/* Overall Container - A clean, centered layout with a glassmorphism effect */
.regulatory-intelligence {
  padding: 40px;
  max-width: 900px;
  margin: 0 auto;
  backdrop-filter: blur(10px) saturate(180%);
  -webkit-backdrop-filter: blur(10px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.75); /* Glassmorphism transparency */
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  font-family: 'Inter', sans-serif; /* Premium font choice */
  color: #2c3e50; /* Dark text on light background */
}

/* List Style - Minimalist and modern, with soft shadows */
.regulatory-intelligence ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.regulatory-intelligence ul li {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 30px;
  margin-bottom: 20px;
  border-radius: 16px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05); /* Soft shadow for depth */
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.regulatory-intelligence ul li:hover {
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15); /* Pronounced shadow on hover */
  transform: translateY(-5px); /* Slight lift for interaction feedback */
}

/* Text - Clean and sleek typography */
.regulatory-intelligence ul li h3 {
  margin-bottom: 10px;
  font-size: 1.4rem; /* Slightly larger for better hierarchy */
  font-weight: 600;
  color: #1a202c; /* Darker for sharper contrast */
}

.regulatory-intelligence ul li p {
  color: #718096;
  font-size: 1rem;
  line-height: 1.6;
}

/* Filters - Modern select dropdowns with sleek styles */
.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  gap: 20px;
}

.filters label {
  font-size: 1rem;
  color: #4a5568;
  display: flex;
  flex-direction: column;
}

.filters select {
  padding: 12px;
  border-radius: 12px;
  border: 1px solid rgba(60, 66, 87, 0.2);
  font-size: 1rem;
  margin-top: 5px;
  color: #2d3748;
  background-color: rgba(255, 255, 255, 0.7); /* Light background with transparency */
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  transition: border-color 0.3s ease;
}

.filters select:focus {
  border-color: #3182ce; /* Subtle interaction feedback */
  outline: none;
}

/* Auto-refresh toggle - Clean switch style */
.auto-refresh {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 1rem;
  color: #4a5568;
}

.auto-refresh input[type="checkbox"] {
  width: 20px;
  height: 20px;
  accent-color: #3182ce; /* Modern blue color */
  border-radius: 50%; /* Rounded toggle for modern design */
  cursor: pointer;
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .filters {
    flex-direction: column;
    gap: 15px;
  }

  .filters label {
    font-size: 0.95rem;
  }

  .filters select {
    font-size: 0.95rem;
  }

  .regulatory-intelligence ul li {
    padding: 20px; /* Adjust padding for mobile */
  }
}

/* Glassmorphism Hover Effect for the cards */
.regulatory-intelligence ul li {
  backdrop-filter: blur(15px) saturate(180%);
  -webkit-backdrop-filter: blur(15px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.2); /* Slight transparency in the background */
}

/* Dark Mode Compatibility */
@media (prefers-color-scheme: dark) {
  .regulatory-intelligence {
    background-color: rgba(20, 20, 20, 0.85);
    color: #e2e8f0; /* Light text for dark mode */
  }

  .filters label,
  .auto-refresh {
    color: #e2e8f0; /* Adjust labels for dark mode */
  }

  .regulatory-intelligence ul li {
    background-color: rgba(30, 30, 30, 0.8);
    color: #f7fafc; /* Light text on cards */
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .filters select {
    background-color: rgba(40, 40, 40, 0.9);
    color: #f7fafc;
  }

  .filters select:focus {
    border-color: #63b3ed; /* Light blue focus in dark mode */
  }

  .auto-refresh input[type="checkbox"] {
    accent-color: #63b3ed; /* Blue toggle in dark mode */
  }
}
