.compliance-tracker {
  padding: 20px;
}

.compliance-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  overflow: hidden;
}

.compliance-table th,
.compliance-table td {
  padding: 15px;
  border-bottom: 1px solid #ecf0f1;
}

.compliance-table th {
  background-color: #3498db;
  color: white;
  font-weight: 600;
  text-align: left;
}

.compliance-table td {
  font-size: 1rem;
  color: #7f8c8d;
}

.compliance-table tr {
  transition: background-color 0.3s ease-in-out;
}

.compliance-table tr:hover {
  background-color: #ecf0f1;
}

.compliance-table tr.non-compliant td {
  color: #e74c3c;
  font-weight: bold;
}

/* Additional CSS for specific details */
.compliance-table td:nth-child(4),
.compliance-table td:nth-child(5) {
  font-style: italic;
  color: #2c3e50;
}

.compliance-table td:nth-child(6) {
  color: #95a5a6;
  font-size: 0.9rem;
}
