.document-repository {
    padding: 20px;
  }
  
  .document-repository ul {
    list-style: none;
    padding-left: 0;
  }
  
  .document-repository ul li {
    margin-bottom: 15px;
  }
  
  .document-repository ul li a {
    background-color: #ffffff;
    padding: 15px 20px;
    display: block;
    border-radius: 8px;
    transition: background-color 0.3s ease-in-out;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  }
  
  .document-repository ul li a:hover {
    background-color: #ecf0f1;
  }
  