/* Base styles for a modern and responsive UI */
body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f7f8fa;
  color: #333;
}

/* Processor container styling */
.processor-container {
  max-width: 95%;
  width: 600px;
  margin: 20px auto;
  padding: 25px;
  background: linear-gradient(135deg, #ffffff, #f4f5f9);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.processor-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
}

/* Header styling */
.processor-container h1 {
  font-size: 2.4rem;
  text-align: center;
  color: #2c3e50;
  margin-bottom: 20px;
  letter-spacing: 0.5px;
  font-weight: 700;
}

/* Text area styling */
.text-input {
  width: 100%;
  height: 120px;
  padding: 15px;
  border: 1px solid #dcdfe6;
  border-radius: 10px;
  font-size: 1rem;
  background-color: #f9fafc;
  transition: border-color 0.3s ease;
}

.text-input:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 8px rgba(52, 152, 219, 0.2);
}

/* Divider styling */
.divider {
  text-align: center;
  margin: 15px 0;
  font-weight: bold;
  font-size: 0.9rem;
  color: #7f8c8d;
  text-transform: uppercase;
}

/* Button styling */
button {
  display: block;
  width: 100%;
  padding: 15px;
  margin: 15px 0;
  background-color: #3498db;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
}

button:hover {
  background-color: #2a80b9;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(42, 128, 185, 0.3);
}

button:disabled {
  background-color: #b0bec5;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

/* Loading state */
.loading {
  text-align: center;
  font-size: 1.2rem;
  color: #7f8c8d;
  font-style: italic;
  margin-top: 10px;
}

/* API response styling */
.api-response {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  margin-top: 25px;
  overflow-x: auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.api-response h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #2c3e50;
}

.api-response pre {
  font-family: 'Source Code Pro', monospace;
  font-size: 0.9rem;
  color: #555;
  line-height: 1.5;
  margin: 0;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .processor-container {
    padding: 20px;
    border-radius: 15px;
  }

  .processor-container h1 {
    font-size: 2rem;
  }

  button {
    padding: 12px;
    font-size: 0.9rem;
  }

  .text-input {
    height: 100px;
    font-size: 0.9rem;
  }

  .api-response h2 {
    font-size: 1.3rem;
  }
}

/* Logo container */
.logo {
  text-align: center;
  font-size: 3rem; /* Massive size for the logo */
  font-weight: 800; /* Bold for prominence */
  color: #3498db; /* Modern blue color */
  margin-bottom: 20px;
  letter-spacing: 2px; /* Slight letter spacing for a modern touch */
  font-family: 'Poppins', sans-serif; /* Elegant, modern font */
  text-transform: uppercase; /* Optional: Gives a clean, professional look */
  transition: transform 0.3s ease, color 0.3s ease;
}

.logo:hover {
  transform: scale(1.05); /* Subtle hover effect */
  color: #2a80b9; /* Slightly darker blue on hover */
}

/* Optional: Add a gradient to the text for extra flair */
.logo span {
  background: linear-gradient(to right, #3498db, #2ecc71);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.badge {
  display: inline-block;
  padding: 0.4em 0.8em;
  font-size: 0.85em;
  color: white;
  border-radius: 5px;
  margin-top: 0.5em;
}

.badge-success {
  background-color: #28a745;
}

.badge-danger {
  background-color: #dc3545;
}

.result-item {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.image-upload-container {
  text-align: center;
  margin: 20px 0;
}
.camera-upload-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #e0e0e0; /* Light gray for a neutral look */
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.camera-upload-button:hover {
  background-color: #f5f5f5; /* Subtle hover effect with a lighter shade */
  transform: scale(1.5);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.camera-icon {
  font-size: 1.2rem; /* Slightly larger icon for modern appeal */
  color: #4a4a4a; /* Neutral dark gray for better contrast */
}

.hidden-input {
  display: none;
}

.image-name {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #4a4a4a;
  font-weight: 500;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px; /* Limit file name width */
  margin: 0 auto;
}

.note {
  font-size: 1rem; /* Makes the text smaller */
  color: #7f8c8d; /* Subtle gray color for less emphasis */
  margin-top: 10px;
  text-align: center; /* Center the note for better visual alignment */
}
