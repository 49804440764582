/* Vendor Management Styles */
.vendor-management {
  padding: 30px;
  background-color: #f8fafc;
  min-height: 100vh;
  font-family: "Space Grotesk", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 20px; /* Gap between notification and main content */
}

/* Main Heading */
h1 {
  font-size: 2rem;
  color: #34495e;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

/* Back Link */
.back-link {
  display: inline-block;
  margin-bottom: 1.5rem;
  color: #3498db;
  font-size: 1rem;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.back-link:hover {
  color: #2980b9;
}

/* Notification Banner - Full Width */
.notification-banner {
  background-color: #fef9e7;
  padding: 15px;
  border-radius: 8px;
  border-left: 4px solid #f1c40f;
  font-family: "Space Grotesk", sans-serif;
  width: 100%; /* Ensure full width */
}

.notification-item {
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-item p {
  margin: 0;
  font-size: 0.9rem;
  color: #d35400;
}

.notification-item button {
  background-color: #e74c3c;
  color: #ffffff;
  border: none;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.85rem;
}

.notification-item button:hover {
  background-color: #c0392b;
}

/* Main Content Area - Flexbox for Vendor List and Details */
.main-content-vendor {
  display: flex;
  gap: 20px; /* Gap between vendor list and vendor details */
  flex: 1;
  padding: 40px 40px 0 40px; /* Top, right, bottom (0), left */
  overflow: visible; /* Ensure no overflow */

}

/* Vendor List */
.vendor-list {
  flex: 1; /* Takes up the left half */
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Vendor Card */
.vendor-card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  cursor: pointer;
}

.vendor-card h3 {
  font-size: 1.4rem;
  color: #2c3e50;
  font-weight: 600;
}

.vendor-card p {
  font-size: 0.95rem;
  color: #7f8c8d;
  margin: 0.25rem 0;
}

.vendor-card:hover {
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
}

/* Vendor Details - Hidden initially, appears when selected */
.vendor-details {
  flex: 1; /* Take up remaining space */
  padding: 30px;
  min-height: fit-content; /* Ensures the height is just enough to fit content */
  max-height: 90vh; /* Prevents card from becoming too tall */
  overflow-y: auto; /* Enable scroll if content exceeds max-height */
  line-height: 1.5;
  border-radius: 10px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  display: none; /* Initially hidden */
  transition: transform 0.4s ease, max-height 0.3s ease; /* Smooth transition for height changes */
}

.vendor-details.active {
  display: block; /* Show when active */
  transform: translateX(0); /* Slide into view when active */
}

.vendor-details h2 {
  font-size: 1.6rem;
  color: #34495e;
  margin-bottom: 1.5rem;
}

.vendor-details p {
  font-size: 1rem;
  color: #7f8c8d;
  margin-bottom: 0.75rem;
}

.vendor-details ul {
  list-style: none;
  padding: 0;
}

.vendor-details ul li {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures buttons stay on the right */
  background-color: #f1f5f9;
  padding: 12px;
  margin: 12px;
  border-radius: 8px;
  color: #2c3e50;
  font-size: 0.95rem;
  gap: 10px;
}


/* Certification Buttons Styling */
.vendor-details ul li button {
  background-color: #3498db;
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 0.85rem;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-left: 10px; /* Adds space between Edit and Delete buttons */
}


.vendor-details ul li button:hover {
  background-color: #2980b9;
  box-shadow: 0 4px 12px rgba(52, 152, 219, 0.2);
}

.vendor-details ul li button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.4);
}

/* Buttons */
button {
  background-color: #3498db;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 0.95rem;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

button:hover {
  background-color: #2980b9;
  box-shadow: 0 4px 12px rgba(52, 152, 219, 0.2);
}

button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.4);
}

/* Responsive Behavior */
@media (max-width: 768px) {
  .main-content {
    flex-direction: column; /* Stack elements vertically on small screens */
  }

  .vendor-details {
    width: 100%;
    margin-top: 20px;
    transform: translateX(100%);
  }

  .vendor-details.active {
    transform: translateX(0);
  }
}

/* Minimal Close Button (X) in Vendor Details */
.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  font-size: 1.4rem;
  color: #8a8d91;
  cursor: pointer;
  transition: color 0.2s ease, transform 0.1s ease;
}

.close-button:hover {
  color: #a0b9da; /* Darker tone on hover */
  transform: scale(1.1); /* Subtle size increase on hover */
}

.close-button:active {
  transform: scale(0.95); /* Slight inward press effect on click */
}

/* Add Vendor Button - Modern Style */
.add-vendor-button {
  background-color: #4CAF50; /* Green for positive action */
  color: #ffffff;
  padding: 12px 24px;
  border: none;
  border-radius: 50px; /* Rounded pill shape */
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.add-vendor-button:hover {
  background-color: #45a049; /* Slightly darker green on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transform: scale(1.02); /* Slight scale up for emphasis */
}

.add-vendor-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.4); /* Green focus ring */
}

.add-vendor-button:active {
  transform: scale(0.98); /* Slightly smaller when clicked */
}
