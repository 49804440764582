/* Centered sign-up container */
.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100vw;
    padding: 10px; /* Ensure minimal padding for small screens */
    box-sizing: border-box;
    z-index: 1;
}

/* Responsive styling */
@media (max-width: 768px) {
    .signup-container {
        padding: 8px; /* Further reduced padding for smaller screens */
    }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

/* Sign-up card */
.signup-card {
    background-color: #ffffff;
    padding: 25px; /* Reduced padding to avoid scrolling on small devices */
    border-radius: 12px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
    max-width: 360px; /* Slightly narrower for better fitting on small screens */
    width: 100%;
    text-align: center;
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    position: relative;
    overflow: hidden;
}

@media (max-width: 480px) {
    .signup-card {
        padding: 16px; /* Optimal padding for very small screens */
    }
}

.signup-card:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.8);
    width: 1000px;
    height: 1000px;
    background: radial-gradient(circle, rgba(69, 123, 157, 0.2), transparent);
    z-index: 0;
    transition: transform 0.4s ease, opacity 0.4s ease;
}

.signup-card:hover:before {
    transform: translate(-50%, -50%) scale(1.2);
    opacity: 1;
}

.signup-card:hover {
    transform: scale(1.03);
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.15);
}

/* Headings and text styling */
.signup-card h1 {
    font-size: 1.6rem; /* Balanced size for smaller devices */
    font-family: "Space Grotesk", sans-serif;
    color: #2c3e50;
    margin-bottom: 16px;
    font-weight: 700;
    letter-spacing: 0.5px;
    position: relative;
    z-index: 1;
}

@media (max-width: 480px) {
    .signup-card h1 {
        font-size: 1.5rem;
    }
}

.signup-card p {
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    color: #7f8c8d;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
}

@media (max-width: 480px) {
    .signup-card p {
        font-size: 0.9rem;
    }
}

/* Form input styling */
.signup-card input,
.signup-card textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 16px;
    border: 2px solid transparent;
    border-radius: 8px;
    background-color: #f4f6f9;
    font-size: 1rem;
    box-sizing: border-box;
    transition: border-color 0.3s ease, background-color 0.3s ease;
    position: relative;
    z-index: 1;
}

.signup-card input:focus,
.signup-card textarea:focus {
    border-color: #3498db;
    background-color: #ffffff;
    outline: none;
}

.signup-card textarea {
    font-family: "Space Grotesk", sans-serif;
    height: 100px;
    resize: none;
}

/* Submit button */
.signup-card button {
    width: 100%;
    padding: 12px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    position: relative;
    z-index: 1;
    letter-spacing: 0.5px;
}

.signup-card button:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
}

.signup-card button:active {
    transform: translateY(0);
}

/* Error message */
.error {
    color: #e74c3c;
    font-size: 0.9rem;
    margin-top: 8px;
    position: relative;
    z-index: 1;
}

/* Subtle input background animation */
.signup-card:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 420px;
    height: 420px;
    background: radial-gradient(circle, rgba(69, 123, 157, 0.1), transparent);
    transform: translate(-50%, -50%) scale(0);
    z-index: 0;
    transition: transform 0.6s ease;
}

.signup-card:hover:after {
    transform: translate(-50%, -50%) scale(1);
}

/* Logo styling */
.logo h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 2rem;
    color: #3498db;
    margin-bottom: 16px;
    font-weight: 900;
    letter-spacing: 1px;
    text-align: center;
}

/* Full-screen container for particles */
#particles-js {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: none;
}

.spinner {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid #fff;
    border-radius: 50%;
    border-top: 2px solid #3498db;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  