/* Dashboard Container */
.dashboard-container {
  padding: 30px; /* Reduced padding */
  display: grid;
  grid-template-columns: 1fr; /* Start with one column */
  width: 100%;
  box-sizing: border-box;
  background-color: #f9fafb; /* Softer background */
}

/* Compliance Overview */
.compliance-overview {
  background-color: #ffffff;
  padding: 20px; /* Reduced padding */
  margin-bottom: 20px; /* Reduced margin */
  border-radius: 10px; /* Slightly smaller border radius */
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05); /* Softened shadow */
}

.compliance-overview h2 {
  margin-bottom: 8px; /* Slightly reduced margin */
  color: #34495e;
  font-size: 1.3rem; /* Slightly smaller font size */
  font-weight: 600;
  font-family: "Space Grotesk", sans-serif;
}

.compliance-overview p {
  margin: 3px 0; /* Reduced margins for better compactness */
  font-size: 0.95rem; /* Slightly smaller font */
  color: #7f8c8d;
  font-family: "Space Grotesk", sans-serif;
}

/* Alert Section */
.alert-section {
  margin-bottom: 15px; /* Reduced margin */
}

.alert-section .alerts {
  background-color: #fef9e7;
  padding: 12px; /* Reduced padding */
  border-left: 4px solid #f1c40f; /* Smaller border */
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.alert-section .alerts h3 {
  margin-bottom: 8px; /* Reduced margin */
  color: #e67e22;
  font-size: 1.1rem; /* Slightly smaller font */
  font-family: "Space Grotesk", sans-serif;
}

.alert-section .alerts ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.alert-section .alerts ul li {
  margin-bottom: 4px;
  color: #d35400;
  font-size: 0.95rem; /* Slightly smaller font */
  font-family: "Space Grotesk", sans-serif;
}

/* Perfume Card Container */
.perfume-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr)); /* Adjusted min-width for more space */
  gap: 20px; /* Slightly reduced gap */
  margin-top: 15px; /* Reduced margin-top */
}

/* Perfume Card */
.perfume-card {
  background-color: #ffffff;
  border-radius: 10px; /* Slightly smaller border radius */
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08); /* Softened shadow */
  padding: 20px; /* Reduced padding */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.perfume-card h3 {
  margin-bottom: 10px; /* Reduced margin */
  color: #2c3e50;
  font-size: 1.1rem; /* Slightly smaller font */
  font-weight: 600;
  font-family: "Space Grotesk", sans-serif;
}

.perfume-card p {
  font-family: "Space Grotesk", sans-serif;
  font-size: 0.95rem; /* Slightly smaller font */
  color: #7f8c8d;
}

.perfume-card:hover {
  transform: scale(1.02); /* More subtle hover effect */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12); /* Reduced shadow intensity */
}

.compliance-badges {
  display: flex;
  gap: 6px; /* Reduced gap */
}

/* Badges */
.badge {
  padding: 5px 10px; /* Reduced padding */
  font-size: 0.8rem; /* Slightly smaller font */
  border-radius: 10px; /* Slightly smaller radius */
  display: inline-block;
  font-weight: 500;
  font-family: "Space Grotesk", sans-serif;
}

.badge-ifra {
  background-color: #16a085;
}

.badge-msds {
  background-color: #2980b9;
}

.badge-allergen {
  background-color: #e74c3c;
}

/* Sorting and Search Controls */
.controls {
  display: flex;
  justify-content: space-between;
  gap: 15px; /* Reduced gap */
  margin-bottom: 15px; /* Reduced margin-bottom */
  align-items: center;
}

.controls input[type="text"] {
  padding: 8px;
  font-size: 0.9rem; /* Slightly smaller font */
  border: 1px solid #bdc3c7;
  border-radius: 5px;
  width: 220px; /* Slightly smaller width */
  background-color: #f9fafb;
  font-family: "Space Grotesk", sans-serif;
}

.controls select {
  padding: 8px;
  font-size: 0.9rem;
  border: 1px solid #bdc3c7;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f9fafb;
  font-family: "Space Grotesk", sans-serif;
}

.controls button {
  padding: 8px 15px; /* Reduced padding */
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 0.95rem; /* Slightly smaller font */
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: "Space Grotesk", sans-serif;
}

.controls button:hover {
  background-color: #2980b9;
}

.controls button.export-button {
  background-color: #2ecc71;
}

.controls button.export-button:hover {
  background-color: #27ae60;
}

/* Formula Details */
.formula-details {
  background-color: #ecf0f1;
  padding: 20px; /* Reduced padding */
  border-radius: 8px; /* Slightly smaller radius */
  width: 25vw;
  max-width: 400px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1); /* Softened shadow */
  display: flex;
  flex-direction: column;
  gap: 15px; /* Reduced gap */
  margin-left: 20px; /* Reduced margin-left */
  font-family: "Space Grotesk", sans-serif;
}

/* Media Queries */
@media (max-width: 1200px) {
  .perfume-card-container {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }

  .formula-details {
    width: 100%;
  }

  .dashboard-container {
    flex-direction: column;
  }

  .controls {
    flex-direction: column;
    align-items: stretch;
  }

  .controls input[type="text"],
  .controls select,
  .controls button {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .perfume-card-container {
    grid-template-columns: 1fr;
  }

  .formula-details {
    display: none;
    width: 100%;
    margin-left: 0;
  }

  .dashboard-container {
    flex-direction: column;
  }
}


/* Main Content Area - Flexbox for Perfume List and Details */
.main-content-perfume {
  display: flex;
  gap: 20px; /* Gap between perfume list and vendor details */
  flex: 1;
  padding: 40px 40px 0 40px; /* Top, right, bottom (0), left */
  overflow: visible; /* Ensure no overflow */

}